@charset "utf-8";

//*---------------------------------------
//  メディアクエリー
// ---------------------------------------*/
 $pc-15inch: 1440px; // PC15インチサイズ
 $pc-13inch: 1280px; // PC13インチサイズ
 $pc-16: 1600px; // PC1600pxサイズ
 $pc-15: 1500px; // PC1500pxサイズ
 $pc-12: 1200px; // PC1200pxサイズ
 $pc-11: 1100px; // PC1100pxサイズ
 $tablet-landscape: 1024px; // iPad横向きサイズ（landscape）
 $tablet-portrait: 768px; // iPad縦向きサイズ（portrait）
 $sp: 767px; // スマートフォンサイズ（汎用サイズ）
 $iphoneL: 414px; // スマートフォンiPhone6/7/8 Plus/XS向けサイズ
 $iphoneM: 375px; // スマートフォンiPhone6/7/8/X向けサイズ
 $android: 360px; // スマートフォンandroid向けサイズ
 $iphoneS: 320px; // スマートフォンiPhone5以下向けサイズ
 
@mixin media($media-width) {
	 // PC15インチサイズ
	 @if $media-width == pc-15inch {
		 @media screen and (max-width: $pc-15inch) { @content }
	 }
	 // PC13インチサイズ
	 @else if $media-width == pc-13inch {
		 @media screen and (max-width: $pc-13inch) { @content }
	 }
	 // PC1600pxサイズ
	 @else if $media-width == pc-16 {
		 @media screen and (max-width: $pc-16) { @content }
	 }
	 // PC1500pxサイズ
	 @else if $media-width == pc-15 {
		 @media screen and (max-width: $pc-15) { @content }
	 }
	 // PC1200pxサイズ
	 @else if $media-width == pc-12 {
		 @media screen and (max-width: $pc-12) { @content }
	 }
	 // PC1100pxサイズ
	 @else if $media-width == pc-11 {
		 @media screen and (max-width: $pc-11) { @content }
	 }
	 // iPad横向きサイズ（landscape）
	 @else if $media-width == tablet-landscape {
		 @media screen and (max-width: $tablet-landscape) { @content }
	 }
	 // iPad縦向きサイズ（portrait）
	 @else if $media-width == tablet-portrait {
		 @media screen and (max-width: $tablet-portrait) { @content }
	 }
	 // スマートフォン用サイズ
	 @else if $media-width == sp {
		 @media screen and (max-width: $sp) { @content }
	 }
	 @else if $media-width == iphoneL {
		 @media screen and (max-width: $iphoneL) { @content }
	 }
	 @else if $media-width == iphoneM {
		 @media screen and (max-width: $iphoneM) { @content }
	 }
	 @else if $media-width == android {
		 @media screen and (max-width: $android) { @content }
	 }
	 @else if $media-width == iphoneS {
		 @media screen and (max-width: $iphoneS) { @content }
	 }
 }
//*---------------------------------------
// 背景画像のパス
//---------------------------------------*/
$img-path:'../images/';
//*---------------------------------------
//  ベースカラー
//---------------------------------------*/
$site-blue: #0c52a1;
$site-yellow: #f6b32c;
$bg-footer: #333;
//*---------------------------------------
// テキストカラー
// ---------------------------------------*/
// テキストのベースカラー
$txt-color: #4d4d4d;
$txt-content-nav-color: #2061a0;
//*---------------------------------------
//  凡用カラーリスト
// ---------------------------------------*/
$white: #FFF;
$red: #FF0000;
$blue: #009be2;
$black: #000;
$tw-color: #00aced;
$fb-color: #305097;
$line-color: #5ae628;
$google-color: #db4a39;
$insta-color: #3f729b;
$tumblr-color: #34526f;
$flickr-blue-color: #0063dc;
$flickr-pink-color: #ff0084;
//*---------------------------------------
//  各要素のボーダーカラー
// ---------------------------------------*/
// common
$border-base: #fff;
//*---------------------------------------
//  フォント
// ---------------------------------------*/
// デフォルトのフォント（源ノ角ゴシック JP）
$def-font: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;

// 源ノ明朝（見出し部分に使用）
$hans-serif-font: source-han-serif-japanese, serif;

// デフォルトのフォントのmixin
@mixin def-font {
  font-family: $def-font;
  font-weight: 400;
  font-style: normal;
}
// デフォルトのフォントのミディアムスタイル
@mixin def-font-medium {
  font-family: $def-font;
  font-weight: 600;
  font-style: normal;
}
// デフォルトのフォントのボールドスタイル
@mixin def-font-bold {
  font-family: $def-font;
  font-weight: 700;
  font-style: normal;
}
// 源ノ明朝フォントのエクストラライトスタイル
@mixin hans-serif-font-el {
  font-family: $hans-serif-font;
  font-weight: 200;
  font-style: normal;
}
// 源ノ明朝フォントのレギュラースタイル
@mixin hans-serif-font {
  font-family: $hans-serif-font;
  font-weight: 400;
  font-style: normal;
}
// 源ノ明朝フォントのミディアムスタイル
@mixin hans-serif-font-medium {
  font-family: $hans-serif-font;
  font-weight: 500;
  font-style: normal;
}
// 源ノ明朝フォントのボールドスタイル
@mixin hans-serif-font-bold {
  font-family: $hans-serif-font;
  font-weight: 600;
  font-style: normal;
}
//*---------------------------------------
//  グラデーションのベースカラー
//---------------------------------------*/
@mixin gradient-red {
	background: rgb(206,71,63); /* Old browsers */
background: -moz-linear-gradient(left, rgba(206,71,63,1) 0%, rgba(196,2,0,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left, rgba(206,71,63,1) 0%,rgba(196,2,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right, rgba(206,71,63,1) 0%,rgba(196,2,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ce473f', endColorstr='#c40200',GradientType=1 ); /* IE6-9 */
}