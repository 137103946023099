@charset "utf-8";

//**********************************************************
//	Clearfix
//**********************************************************
// def clearfix
@mixin clearfix {
  overflow: hidden;
  *zoom: 1;
}
// pie clearfix
@mixin pie-clearfix {
	*zoom: 1;

	&:after {
		content: "";
		display: table;
		clear: both;
	}
}
//**********************************************************
//	Text-indent
//**********************************************************
@mixin text-indent {
	overflow: hidden;
	text-indent: -99999px;
	white-space: nowrap;
}
//**********************************************************
//	ボーダースタイル
//**********************************************************
@mixin border($way: all,$style: solid,$weight: 1px,$color: $border-base) {
	@if($way == all) {
		border:$style $weight $color;
	} @else if ($way == top) {
		border-top:$style $weight $color;
	} @else if ($way == right) {
		border-right:$style $weight $color;
	} @else if ($way == bottom) {
		border-bottom:$style $weight $color;
	} @else if ($way == left) {
		border-left:$style $weight $color;
	}
}
//**********************************************************
//	リキッドスタイル
//**********************************************************
@mixin wh($liq-w: 100%,$liq-h: auto) {
// デフォルトが100%,autoとなります。どちらかの値が特に必要ないという場合は、引数で値をnoneにするとその部分のプロパティが消えます。
	@if $liq-w    != '' { 
		@if $liq-w == none{
		} @else{
			width:$liq-w;
		}
	}
	@if $liq-h    != '' { 
		@if $liq-h == auto{
		} @else{
			height:$liq-h;
		}
	}
}
//**********************************************************
//	vwでのフォントサイズ調整
//**********************************************************
// 第一引数で基準となる画面幅のフォントサイズ、第二引数で基準となる画面幅を入力
@function get_vw($size, $viewport:320){
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}

@mixin font-vw($font_size:10){
  font-size: $font_size * 1px;
  font-size: get_vw($font_size);
}
//**********************************************************
//	画像のフルード化のmixin
//**********************************************************
@mixin img-fluid {
  img {
    display:block;
    width: 100%;
    height: auto;
    line-height:1;
    backface-visibility: hidden;
  }
} // img-fluid end
//**********************************************************
//	position:absoluteの方位指定のmixin
//**********************************************************

@mixin absolute ($top: auto,$right: auto,$bottom: auto,$left: auto) {
	position: absolute;
	@if $top    != '' { 
		@if $top == auto{  
		} @else{
			top: $top;
		}
	}
	@if $right    != '' { 
		@if $right == auto{
		} @else{
			right: $right;
		}
	}
	@if $bottom    != '' { 
		@if $bottom == auto{
		} @else{
			bottom: $bottom;
		}
	}
	@if $left    != '' { 
		@if $left == auto{
		} @else{
			left: $left;
		}
	}
}
//※指定するときは @include absolute(12px,10px,9px,14px); というかんじで。
//何も指定しない位置に関してはautoで。

//**********************************************************
//	position:fixedの方位指定のmixin
//**********************************************************

@mixin fixed ($top: auto,$right: auto,$bottom: auto,$left: auto) {
	position: fixed;
	@if $top    != '' { 
		@if $top == auto{  
		} @else{
			top: $top;
		}
	}
	@if $right    != '' { 
		@if $right == auto{
		} @else{
			right: $right;
		}
	}
	@if $bottom    != '' { 
		@if $bottom == auto{
		} @else{
			bottom: $bottom;
		}
	}
	@if $left    != '' { 
		@if $left == auto{
		} @else{
			left: $left;
		}
	}
}
//※指定するときは @include absolute(12px,10px,9px,14px); というかんじで。
//何も指定しない位置に関してはautoで。

//**********************************************************
//	position:relativeの方位指定のmixin
//**********************************************************

@mixin relative ($top: auto,$right: auto,$bottom: auto,$left: auto) {
	position: relative;
	@if $top    != '' { 
		@if $top == auto{  
		} @else{
			top: $top;
		}
	}
	@if $right    != '' { 
		@if $right == auto{
		} @else{
			right: $right;
		}
	}
	@if $bottom    != '' { 
		@if $bottom == auto{
		} @else{
			bottom: $bottom;
		}
	}
	@if $left    != '' { 
		@if $left == auto{
		} @else{
			left: $left;
		}
	}
}
//※指定するときは @include relative(12px,10px,9px,14px); というかんじで。
//何も指定しない位置に関してはautoで。

//**********************************************************
//	上下中央配置のスタイルのmixin
//**********************************************************
@mixin center-posi($position: relative) {
	position: $position;
  	top: 50%;
  	transform: translateY(-50%);
}

@mixin no-center-posi($position: static) {
	position: static;
  	top: 0;
  	transform: translateY(0);
}

//**********************************************************
//	Flex配置のスタイルのmixin
//**********************************************************
@mixin flex-posi($justify-content: center, $align-items: center) {
	display: flex;
    justify-content: $justify-content;
    align-items: $align-items;
}

//**********************************************************
//	float系のmixin
//**********************************************************
// floatのブラウザサポート対応 ※float:leftにさせる場合
@mixin float-left-fix {
	float:left;
	display:inline-block;
}
// floatのブラウザサポート対応 ※float:rightにさせる場合
@mixin float-right-fix {
	float:right;
	display:inline-block;
}
//**********************************************************
//	opacityのスタイル(IE対応)
//**********************************************************
@mixin opacity($ie: 50,$css3: 0.5) { // デフォルトはIE:50 その他:0.5
	filter: alpha( opacity=$ie );
	-ms-filter: "alpha(opacity=#{$ie})";
	//-khtml-opacity: $css3;
    -moz-opacity: $css3;
	opacity: $css3;
}
//**********************************************************
//	PIE
//**********************************************************
@mixin pie {
	behavior:$pie-behavior;
	position:relative;
}
@mixin pie-nonposi {
	behavior:$pie-behavior;
}
//**********************************************************
//	主にテキストリンクのプレースホルダーセレクタ
//**********************************************************

// リンクスタイル　※共通スタイルにしたい要素にextendさせる
@mixin link-style {
	// default
	@include def-font;
	@include link-colors($txt-color,$txt-color);
	text-decoration:none;
	transition: all .3s cubic-bezier(0.320, 0.625, 0.580, 1.000);
	
	&:hover {
		opacity: .6;
		transition: all .3s cubic-bezier(0.320, 0.625, 0.580, 1.000);
	}
}
//**********************************************************
//	link-colors
//**********************************************************
// リンクカラー
@mixin link-colors($def:#4d4d4d,$hover:#4d4d4d) {
	// def
	color:$def;
	
	&:hover {
		color:$hover;
	}
}
//**********************************************************
//	CSS3 Animation
//**********************************************************
@mixin animation($animation-name) {
  -webkit-animation: $animation-name;
  -moz-animation: $animation-name;
  animation: $animation-name;
  //-webkit-animation-fill-mode: both;
  //-moz-animation-fill-mode: both;
  //animation-fill-mode: both;
}

@mixin animation-delay($delay) {
  -webkit-animation-delay: $delay;
  -moz-animation-delay: $delay;
  animation-delay: $delay;
}
//**********************************************************
//	z-indexのプレースホルダーセレクタ
//**********************************************************
@mixin zindex-1 { z-index:-1; }
@mixin zindex0 { z-index:0; }
@mixin zindex1 { z-index:1; }
@mixin zindex2 { z-index:2; }
@mixin zindex3 { z-index:3; }
@mixin zindex4 { z-index:4; }
@mixin zindex5 { z-index:5; }
@mixin zindex6 { z-index:6; }
@mixin zindex7 { z-index:7; }
@mixin zindex8 { z-index:8; }
@mixin zindex9 { z-index:9; }
@mixin zindex10 { z-index:10; }
@mixin zindex11 { z-index:11; }
@mixin zindex12 { z-index:12; }
@mixin zindex13 { z-index:13; }
@mixin zindex14 { z-index:14; }
@mixin zindex15 { z-index:15; }
@mixin zindex16 { z-index:16; }
@mixin zindex17 { z-index:17; }
@mixin zindex18 { z-index:18; }
@mixin zindex19 { z-index:19; }
@mixin zindex20 { z-index:20; }
@mixin zindex990 { z-index:990; }
@mixin zindex991 { z-index:991; }
@mixin zindex992 { z-index:992; }
@mixin zindex993 { z-index:993; }
@mixin zindex994 { z-index:994; }
@mixin zindex995 { z-index:995; }
@mixin zindex996 { z-index:996; }
@mixin zindex997 { z-index:997; }
@mixin zindex998 { z-index:998; }
@mixin zindex999 { z-index:999; }