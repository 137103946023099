@charset "UTF-8";

/* ------------------------------
    ヒーロータイトル
------------------------------ */
.hero {
    @include wh;
    margin: 0 auto;
    background: url(#{$img-path}bg-hero.png) 100% 0 no-repeat;
    background-size: percentage(0.68875);
    
    @include media(sp) {
        height: auto;
        padding: 18% 0;
        background-size: 90%;
        background-position: 200% 0;
    }
    @include media(android) {
        background-size: 94%;
        background-position: 280% 0;
    }

    // インナーの独自スタイル
    .l-inner {
        @include wh(980px,37.5vw);

        @include media(pc-16) {
            height: 37.5vw;
        }
        @include media(tablet-landscape) {
            width: 90%;
        }
        @include media(sp) {
            width: 100%;
            height: auto;
        }
    } // l-inner end
    
    // 見出し
    &__title {
        @include center-posi;
        @include hans-serif-font-el;
        color: $site-blue;

        @include media(sp) {
            @include no-center-posi;
            margin-left: 5%;
        }

        // 英語
        &__en {
            display: block;
            margin-bottom: 16px;
            margin-left: 100px;
            color: $site-blue;
            font-size: 18px;
            line-height: (21.6/18);

            @include media(tablet-landscape) {
                margin-bottom: 10px;
                margin-left: 88px;
                font-size: 16px;
            }
            @include media(sp) {
                margin-bottom: 6px;
                margin-left: 46px;
                font-size: 13px;
                text-shadow: 0 0 5px rgba(255,255,255,0.8);
            }
        }

        // 日本語
        &__jp {
            display: block;
            color: $site-blue;
            font-size: 48px;
            line-height: (57.6/48);

            @include media(tablet-landscape) {
                font-size: 43px;
            }
            @include media(sp) {
                font-size: 28px;
                text-shadow: 0 0 5px rgba(255,255,255,0.8);
            }
        }
    } // &__title end

} // hero end

/* ------------------------------
    コンテンツナビゲーション
------------------------------ */
.content-nav {
    @include wh;
    padding: 20px 0;
    background: #f0f0f0;

    @include media(sp) {
        padding: 40px 20px 30px;
    }

    // インナーの独自スタイル
    .l-inner {
        display: flex;

        @include media(sp) {
            display: inline;
        }
    } // l-inner end

    // リストスタイル
    &__list {

        @include media(sp) {
            text-align: center;
        }

        // 子リストスタイル
        &__item {
            @include center-posi;
            display: inline-block;
            padding-right: 16px;
            padding-left: 16px;
            @include border(left,solid,1px,$txt-content-nav-color);

            @include media(sp) {
                margin-bottom: 12px;

                &:nth-of-type(4) {
                    border-left: 0;
                }
            }

            &:first-child {
                padding-left: 0;
                border-left: 0;
            }
        } // li end

        // リンクスタイル
        a {
            @include link-colors($txt-content-nav-color,$txt-content-nav-color);
            font-size: 16px;
            line-height: 1;

            @include media(tablet-landscape) {
                font-size: 15px;
            }
            @include media(sp) {
                font-size: 14px;
            }
        } // a end

    } // &__list end

    // 求人情報リンク
    &__recruit-link {
        display: block;
        width: 300px;
        padding: 22px 0;
        margin-left: auto;
        text-align: center;
        @include link-colors(#333,#333);
        font-size: 22px;
        background: url(#{$img-path}icon-drop-arrow.svg) 95% 55% no-repeat $site-yellow;
        background-size: 16.7px;

        @include media(tablet-landscape) {
            width: 270px;
            padding: 18px 0;
            font-size: 20px;
        }
        @include media(sp) {
            width: 250px;
            padding: 16px 0;
            margin: 0 auto;
            font-size: 16px;
            background-size: 14px;
        }
    } // &__recruit-link end

} // content-nav end

/* ------------------------------
    ベースレイアウトの共通セクション
------------------------------ */
.l-content-section {

    // 見出し
    &__title {
        text-align: center;
        color: $site-blue;

        // 英語
        &__en {
            display: block;
            margin-bottom: 12px;
            text-align: center;
            @include hans-serif-font;
            color: $site-blue;
            font-size: 18px;
            line-height: (21.6/18);

            @include media(sp) {
                margin-bottom: 6px;
                font-size: 13px;
            }
        }

        // 日本語
        &__jp {
            display: block;
            color: $site-blue;
            @include hans-serif-font-bold;
            font-size: 34px;
            line-height: (40.8/34);
            letter-spacing: 3px;

            @include media(sp) {
                font-size: 24px;
            }
        }

    } // &__title end

    // テーマ別スタイル
    &.theme-message-section {
        padding: 60px 0 80px;

        @include media(sp) {
            padding: 30px 0;
        }

        // テキスト
        .theme-message-section__text {
            font-size: 16px;
            line-height: 2.16;

            @include media(sp) {
                font-size: 13px;
                line-height: 1.75;
            }
        } // &__text end

        .l-content-section__title__jp {
            margin-bottom: 42px;

            @include media(sp) {
                margin-bottom: 30px;
            }
        }
    } // &.theme-message-section end

    &.theme-benefits-section {
        padding: 74px 0 124px;
        background: #e5ecf3;

        @include media(sp) {
            padding: 30px 0;
        }

        .l-content-section__title__jp {
            margin-bottom: 34px;

            @include media(sp) {
                margin-bottom: 30px;
            }
        }
    } // &.theme-benefits-section end

    &.theme-voice-section {
        padding: 87px 0 0;

        @include media(sp) {
            padding: 30px 0 6px;
        }

        .l-content-section__title__jp {
            margin-bottom: 62px;

            @include media(sp) {
                margin-bottom: 30px;
            }
        }

    } // &.theme-voice-section end

    &.theme-career-section {
        padding: 71px 0 80px;
        background: #f0eeea;

        @include media(sp) {
            padding: 30px 0;
        }

        .l-content-section__title__jp {
            margin-bottom: 46px;

            @include media(sp) {
                margin-bottom: 30px;
            }
        }
    } // &.theme-career-section end

    &.theme-flow-section {
        padding: 76px 0 102px;
        background: $site-blue;

        @include media(sp) {
            padding: 30px 0;
        }

        .l-content-section__title__en,
        .l-content-section__title__jp {
            color: $white;
        }

        .l-content-section__title__jp {
            margin-bottom: 38px;

            @include media(sp) {
                margin-bottom: 30px;
            }
        }

    } // &.theme-flow-section end

    &.theme-job-info-section {
        padding: 76px 0 77px;
        background: url(#{$img-path}bg-job-info.png) center top no-repeat;
        background-size: cover;

        @include media(sp) {
            padding: 30px 0;
        }

        .l-content-section__title__en,
        .l-content-section__title__jp {
            color: $white;
        }
        .l-content-section__title__jp {
            margin-bottom: 42px;

            @include media(sp) {
                margin-bottom: 30px;
            }
        }

    } // &.theme-job-info-section end

} // l-content-section end

/* ------------------------------
    Philosophy
------------------------------ */
.philosophy-block {
    @include wh(1600px,690px);
    margin: 0 auto;
    padding: 82px 0;
    background: url(#{$img-path}bg-philosophy.png) center top no-repeat;
    background-size: cover;

    @include media(pc-16) {
        @include wh;
    }
    @include media(sp) {
        width: 100%;
        height: auto;
        padding: 13vw 0 2vw;
        background-size: 100%;
    }

    .l-inner {
        display: flex;
    }

    &__section {
        @include wh(percentage(670/980));
        padding: 34px 75px 60px 65px;
        margin-left: percentage(-66/980);
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        background: rgba(255,255,255,.8);

        @include media(pc-12) {
            margin-left: 0;
        }
        @include media(tablet-landscape) {
            padding: (34px/1.2) (75px/1.5) (60px/1.5) (65px/1.5);
        }
        @include media(sp) {
            width: 100%;
            height: auto;
            padding: 0;
            margin-left: 0;
            box-shadow: none;
            background: none;
        }

        // 見出し
        &__title {

            // 英語
            &__en {
                display: block;
                margin-bottom: 16px;
                @include hans-serif-font;
                color: $site-blue;
                font-size: 18px;
                line-height: (21.6/18);

                @include media(sp) {
                    margin-bottom: 6px;
                    font-size: 13px;
                }
            }

            // 日本語
            &__jp {
                margin-bottom: 29px;
                display: block;
                color: $site-blue;
                @include hans-serif-font-bold;
                font-size: 32px;
                line-height: (57.6/48);

                @include media(sp) {
                    margin-bottom: 20vw;
                    font-size: 24px;
                }
            }
        } // &__title end

        &__description {
            font-size: 16px;
            line-height: 2.28;

            @include media(sp) {
                font-size: 13px;
                line-height: 1.75;
            }
        }
    }

} // philosophy-block end

/* ------------------------------
    Ideal
------------------------------ */
.ideal-block {
    @include wh(1600px,630px);
    margin: -110px auto 0;
    padding: 100px 0;
    background: url(#{$img-path}bg-ideal.png) center top no-repeat;
    background-size: cover;

    @include media(pc-16) {
        @include wh;
        margin: -110px auto 0;
    }
    @include media(sp) {
        width: 100%;
        height: auto;
        padding: 13vw 0 3vw;
        margin: 0 auto;
        margin-left: 0;
        background-size: 100%;
    }

    .l-inner {
        display: flex;
    }

    &__section {
        @include wh(percentage(570/980));
        //width: 670px;
        //height: 482px;
        padding: 43px 50px (67px-14.5px) 41px;
        margin-left: auto;
        margin-right: percentage(-51/980);
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        background: rgba(255,255,255,.8);

        @include media(tablet-landscape) {
            padding: (43px/1.2) (50px/1.2) ((67px-14.5px)/1.7) (41px/1.2);
        }
        @include media(pc-12) {
            margin-right: 0;
        }
        @include media(sp) {
            width: 100%;
            height: auto;
            padding: 0;
            margin-right: 0;
            box-shadow: none;
            background: none;
        }

        // 見出し
        &__title {
            @include media(sp) {
            text-align: right;
        }

            // 英語
            &__en {
                display: block;
                margin-bottom: 16px;
                @include hans-serif-font;
                color: $site-blue;
                font-size: 18px;
                line-height: (21.6/18);

                @include media(sp) {
                    margin-bottom: 6px;
                    font-size: 13px;
                    color: $black;
                    text-shadow: 0 0 4px rgba(255,255,255,.9);
                }
            }

            // 日本語
            &__jp {
                margin-bottom: 40px;
                display: block;
                color: $site-blue;
                @include hans-serif-font-bold;
                font-size: 32px;
                line-height: (57.6/48);

                @include media(sp) {
                    margin-bottom: 20vw;
                    font-size: 24px;
                    color: $black;
                    text-shadow: 0 0 5px rgba(255,255,255,.8);
                }
            }
        } // &__title end

        &__description {
            display: flex;
            padding: 16.5px 0 14.5px;
            border-top: dashed 1px #b0b0b0;

            &:nth-of-type(1) {
                padding: 0 0 14.5px;
                border-top: 0;
            }
            
            dt {
                margin-right: 21px;
                @include hans-serif-font-el;
                font-size: 39px;
                color: $site-blue;
                line-height: 1;

                @include media(sp) {
                    margin-top: -1px;
                    margin-bottom: 6px;
                    font-size: 20px;
                }
            }

            dd {
                @include hans-serif-font;
                font-size: 20px;
                line-height: 1.33;
                color: #333;

                @include media(sp) {
                    font-size: 15px;
                }
            }
        }
    }

} // ideal-block end

/* ------------------------------
    福利厚生
------------------------------ */
.benefits-list {
    position: relative;
    display: flex;
    justify-content: center;
    text-align: center;

    @include media(tablet-landscape) {
        @include wh(90%);
        margin: 0 auto;
    }
    @include media(sp) {
        display: block;
        @include wh(90%);
    }

    &__item {
        vertical-align: top;
        display: inline-block;
        @include wh(320px);
        padding: 30px 0;
        margin: 0 5px;
        background: $white;

        @include media(sp) {
            @include wh;
            padding: 24px 0 22px;
            margin: 16px 0 0;

            &:nth-of-type(1) {
                margin: 0;
            }
        }
    }

    &__catch {
        @include hans-serif-font-bold;
        color: $site-blue;
        font-size: 18px;
        line-height: 1.2;
        letter-spacing: 1.8px;

        @include media(sp) {
            font-size: 15px;
        }
    }

    &__icon-01 {
        @include wh(105.8px);
        margin: 22.7px auto 27.6px;

        @include media(sp) {
            @include wh((105.8px/1.5));
            margin: (22.7px/1.2) auto (27.6px/1.2);
        }
    }

    &__icon-02 {
        @include wh(150.8px);
        margin: 23.9px auto 30px;

        @include media(sp) {
            @include wh((150.8px/1.5));
            margin: (23.9px/1.2) auto (30px/1.2);
        }
    }

    &__icon-03 {
        @include wh(152.025px);
        margin: 37.4px auto 26px;

        @include media(sp) {
            @include wh((152.025px/1.5));
            margin: (37.4px/1.2) auto (26px/1.2);
        }
    }

    &__description {
        padding: 0 30px;
        text-align: left;
        font-size: 14px;
        line-height: 1.54;

        @include media(sp) {
            padding: 0 24px;
            font-size: 13px;
            line-height: 1.5;
        }
    }

} // benefits-list end

/* ------------------------------
    先輩の声
------------------------------ */
.interviewed {
    @include absolute(0,0);
    @include wh(percentage(400/980));

    @include media(sp) {
        position: static;
        @include wh;
    }

    &__img {
        margin-bottom: 19px;
        box-shadow: 5px 5px 5px rgba(0,0,0,.2);

        @include media(sp) {
            margin-bottom: 12px;
        }
    }

    &__branch {
        text-align: right;
        font-size: 16px;
        line-height: 1.34;

        @include media(sp) {
            margin-bottom: 4px;
            font-size: 14px;
        }
    }

    &__join {
        text-align: right;
        font-size: 14px;
        line-height: 1.2;

        @include media(sp) {
            font-size: 12px;
        }
    }

}

.voice-description {
    @include wh(percentage(530/980));
    padding-bottom: 18px;

    @include media(sp) {
        @include wh;
        padding: 30px 0 6px;
    }

    dt {
        margin-bottom: 14px;
        @include hans-serif-font-bold;
        font-size: 18px;
        line-height: 1.19;
        color: $site-blue;

        @include media(sp) {
            margin-bottom: 12px;
            font-size: 15px;
        }
    }

    dd {
        margin-bottom: 43px;
        font-size: 14px;
        line-height: 1.54;

        @include media(sp) {
            margin-bottom: 24px;
            font-size: 13px;
        }
    }

} // voice-description end

// バナーコンテンツ
.banner-block {
    padding: 33px 0 30px;
    margin-bottom: 57px;
    background: #e5ecf3;

    @include media(sp) {
        padding: 22px 3% 26px;
        margin-bottom: 30px;
    }

    &__img {
        display: block;
        @include wh(1948px/2);
        margin: 0 auto;

        @include media(sp) {
            @include wh;
        }
    }

} // banner-block end

/* ------------------------------
    キャリアパス
------------------------------ */
.career-block {
    width: 940px;
    padding: 53.3px 0 61px 63px; 
    margin: 0 auto;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background: $white;

    @include media(sp) {
        width: 90%;
        padding: 16px; 
    }

    &__description {

        dt {
            margin-bottom: 10px;
            @include hans-serif-font-bold;
            font-size: 20px;
            color: $site-blue;

            @include media(sp) {
                margin-bottom: 8px;
                font-size: 15px;
            }
        }

        dd {
            font-size: 16px;
            line-height: 1.66;

            @include media(sp) {
                font-size: 13px;
                line-height: 1.54;
            }
        }

    } // &__description end

    &__flow-wrap {
        display: flex;
        margin-top: 45px;

        @include media(sp) {
            margin-top: 24px;
        }

        &__step {
            @include wh(135px);
            margin-left: 7px;

            @include media(sp) {
                margin-left: 0;
                @include wh(90px);
            }

            li {
                position: relative;
                text-align: center;

                @include media(sp) {
                    @include wh;
                }

                &:nth-of-type(1) {
                    padding: 30px 0 58px;
                    background: url(#{$img-path}bg-step-arrow_01.png) center top no-repeat;
                    background-size: 100%;
                    @include zindex5;

                    @include media(sp) {
                        padding: 11vw 0;
                        background-size: cover;
                    }
                    @include media(iphoneM) {
                        padding: 14vw 0 18vw;
                    }
                    @include media(iphoneS) {
                        padding: 18vw 0 17vw;
                    }
                }
                &:nth-of-type(2) {
                    margin-top: -31px;
                    padding: 66px 0 90px;
                    background: url(#{$img-path}bg-step-arrow_02.png) center top no-repeat;
                    background-size: 100%;
                    @include zindex4;

                    @include media(sp) {
                        padding: 15vw 0 15vw;
                        background-size: cover;
                    }
                    @include media(iphoneM) {
                        padding: 18vw 0 13vw;
                    }
                    @include media(android) {
                        padding: 19vw 0 13vw;
                    }
                    @include media(iphoneS) {
                        padding: 22vw 0 17vw;
                    }
                }
                &:nth-of-type(3) {
                    margin-top: -49px;
                    padding: 52px 0 84px;
                    background: url(#{$img-path}bg-step-arrow_03.png) center top no-repeat;
                    background-size: 100%;
                    @include zindex3;

                    @include media(sp) {
                        padding: 20vw 0 12vw;
                        background-size: cover;
                    }
                    @include media(iphoneM) {
                        padding: 23vw 0 17vw;
                    }
                    @include media(android) {
                        padding: 26vw 0 16vw;
                    }
                    @include media(iphoneS) {
                        padding: 31vw 0 20vw;
                    }
                }
                &:nth-of-type(4) {
                    margin-top: -29px;
                    padding: 54px 0 82px;
                    background: url(#{$img-path}bg-step-arrow_04.png) center top no-repeat;
                    background-size: 100%;
                    @include zindex2;

                    @include media(sp) {
                        padding: 13vw 0 9vw;
                        background-size: cover;
                    }
                    @include media(iphoneM) {
                        padding: 18vw 0 17vw;
                    }
                    @include media(android) {
                        padding: 22vw 0 20vw;
                    }
                    @include media(iphoneS) {
                        padding: 24vw 0 20vw;
                    }
                }
                &:nth-of-type(5) {
                    margin-top: -29px;
                    padding: 50px 0 37px;
                    background: url(#{$img-path}bg-step-arrow_05.png) center top no-repeat;
                    background-size: 100%;
                    @include zindex1;

                    @include media(sp) {
                        padding: 15vw 0 9vw;
                        background-size: cover;
                    }
                    @include media(iphoneM) {
                        padding: 17vw 0 11vw;
                    }
                    @include media(android) {
                        padding: 20vw 0 13vw;
                    }
                    @include media(iphoneS) {
                        padding: 22vw 0 15vw;
                    }
                }

            } // li end

            .number {
                padding: 6px 23.5px;
                border-radius: 18px;
                font-size: 16px;
                line-height: 1.2;
                color: $site-blue;
                background: $white;

                @include media(sp) {
                    padding: 2px 6px;
                    border-radius: 20px;
                    font-size: 11px;
                }
            }

            .text {
                margin-top: 20px;
                color: $white;
                font-size: 20px;

                @include media(sp) {
                    margin-top: 12px;
                    font-size: 13px;
                }
            }

        } // &__step end

        &__list {
            @include wh(560px);
            margin-right: 137px;
            margin-left: auto;

            @include media(sp) {
                @include wh(85%);
                margin-right: 0;
            }
            @include media(iphoneS) {
                @include wh(90%);
            }
        }

        &__description {
            padding: 19px 0 24px 69px;
            background: #e5ecf3;

            @include media(sp) {
                padding: 12px;
            }

            dt {
                margin-bottom: 10px;
                @include hans-serif-font-bold;
                font-size: 20px;
                color: $site-blue;

                @include media(sp) {
                    margin-bottom: 6px;
                    font-size: 15px;
                }
            }

            dd {
                font-size: 14px;
                line-height: 1.54;

                @include media(sp) {
                    font-size: 12px;
                    line-height: 1.5;
                }
            }
        }

        &__border {
            @include wh(5px,35px);
            margin: 0 auto;
            background: $site-yellow;

            @include media(sp) {
                @include wh(3px,24px);
            }
            @include media(iphoneS) {
                @include wh(2px,18px);
            }
        }

    } // &__flow-wrap end

} // career-block end

/* ------------------------------
    採用フロー
------------------------------ */
.entry-flow-list {
    position: relative;
    left: 24px;
    @include wh(770px);
    margin: 0 auto;

    @include media(sp) {
        position: static;
        left: 0;
        @include wh;
        margin: 0 auto;
    }

    // 子リストスタイル
    &__item {
        position: relative;
        display: flex;
        //padding: 33px 0 30px;
        margin-top: 20px;
        background: $white;

        @include media(sp) {
            width: 100%;
            min-height: 90px;
            margin-top: 14px;
        }

        &:first-child {
            margin-top: 0;
        }

        // アイコン
        &__icon-01 {
            @include wh(108.14px);
            margin: 38.4px 43px 28.5px 47.9px;

            @include media(sp) {
                @include wh(108.14px/2);
                margin: auto auto auto 20px;
            }
        }

        &__icon-02 {
            @include wh(77.63px);
            margin: 28.2px 56.7px 23.2px 64.7px;

            @include media(sp) {
                @include wh(77.63px/2);
                margin: auto 11px auto 28px;
            }
        }

        &__icon-03 {
            @include wh(89.12px);
            margin: 44px 54.3px 40px 56.7px;

            @include media(sp) {
                @include wh(89.12px/2);
                margin: auto 11px auto 23px;
            }
        }

        &__icon-04 {
            @include wh(96.28px);
            margin: 33px 50.5px 34px 52px;

            @include media(sp) {
                @include wh(96.28px/2);
                margin: auto 8px auto 22px;
            }
        }

        &__icon-05 {
            @include wh(81.3px);
            margin: 50.9px 56.3px 44.2px 61.4px;

            @include media(sp) {
                @include wh(81.3px/2);
                margin: auto 10px auto 27px;
            }
        }

        // ナンバー
        &__num {
            margin-top: 16px;
            margin-right: 15px;
            @include hans-serif-font;
            font-size: 44px;
            line-height: 1.75;
            color: $site-blue;

            @include media(sp) {
                margin: auto;
                font-size: 28px;
                vertical-align: middle;
            }
        }

        // 説明
        &__desc {
            margin-top: 31px;
            @include media(sp) {
                width: 60%;
                margin: auto 12px auto auto;
            }
            dt {
                font-size: 24px;
                font-weight: 600;
                line-height: 1.75;
                color: $site-blue;

                @include media(sp) {
                    font-size: 14px;
                }
                
            }

            dd {
                font-size: 18px;

                @include media(sp) {
                    font-size: 12px;
                    line-height: 1.6;
                }
            }
        }

        // アローアイコン
        &__arrow {
            @include absolute(auto,auto,-30px,80px);
            @include wh(39.6px);
            @include zindex2;

            @include media(sp) {
                @include absolute(auto,auto,(-30px/1.5),34px);
                @include wh(39.6px/1.5);
            }
        }
    }

    // 注釈（PC）
    &__notes {
        @include absolute(0,auto,auto,53px);
        @include wh(24.15px);

        @include media(sp) {
            display: none;
        }
    }

    // 注釈（SP）
    &__notes-sp {
        display: none;

        @include media(sp) {
            display: block;
            margin-top: 14px;
            color: $white;
            font-size: 12px;
        }
    }

} // entry-flow-list end

/* ブラウザのUA stylesheetをリセット */
.labelBox * ,
.sp_map * {
  margin: 0;
  padding: 0;
  font: inherit;
	list-style: none;
}
/* PC版レイアウト調整 */
.box_map {
	width: 980px;
	margin: 0 auto;
    background-color: #fff;
}
.box_map_inner {
    padding: 66px 40px 41px;
	//padding: 40px;
}

/* PC版地図＿ラベルの設定 */
.labelBox {
	z-index: 2;
  position: absolute;
  line-height: 1.2;
  text-decoration: none;
}
.labelBox a {
  text-decoration: none;
  color: #ccc;
  pointer-events: none;
  cursor: default;
}
.labelBox h3 {
  font-size: 22px;
  position: relative;
  line-height: 1.5;
  margin-bottom: 12px;
  padding-bottom: 6px;
}
.labelBox ul {
  padding-right: 6px;
}
.labelBox li {
  display: inline-block;
  position: relative;
  padding: 0;
}
.pref a{
  display: inline-block;
  font-size: 14px;
  line-height: 1.75;
}
.pref a::before {
  content: "・";
}
/* 求人がある地域・県 */
.enable h3 a,
.pref_enable a {
  color: #666;
  pointer-events: all;
  cursor: pointer;
}
.enable h3 a:hover,
.pref_enable a:hover {
  color: #ebaa4a;
}
/* ラベルの位置調整 */
.area1 {
  top: 242px;
  left: 743px;
}
.area2 {
  top: 418px;
  left: 636px;
}
.area3 {
  top: 121px;
  left: 302px;
}
.area4 {
  top: 450px;
  left: 505px;
}
.area5 {
  top: 121px;
  left: 70px;
}
.area6 {
  top: 496px;
  left: 369px;
}
.area7 {
  top: 249px;
  left: 70px;
}
/* ラベルの線＿全体設定 */
.labelBox h3:before,
.labelBox h3:after {
  content: "";
  background-color: #666;
  position: absolute;
}
.enable h3:before,
.enable h3:after {
  content: "";
  background-color: #666;
  position: absolute;
}
.labelBox h3:before {
  height: 1px;
  bottom: 0;
}
/* ラベルの線＿北海道・東北 */
.area1 h3:before {
  width: 100%;
  right: 0;
}
/* ラベルの線＿関東 */
.area2 h3:before {
  width: 100%;
  right: 0;
}
/* ラベルの線＿甲信越・北陸 */
.area3 h3:before {
  width: 100%;
  left: 0;
}
/* ラベルの線＿東海 */
.area4 h3:before {
  width: 100%;
  left: 0;
}
.area4 h3:after {
  height: 50px;
  bottom: 0;
  left: 0;
}
/* ラベルの線＿関西 */
.area5 h3:before {
  width: 100%;
  left: 0;
}
.area5 h3:after {
  height: 150%;
  top: 30px;
  right: 0;
}
/* ラベルの線＿中国・四国 */
.area6 h3:before {
  width: 100%;
  left: 0;
}
.area6 h3:after {
  height: 40px;
  bottom: 0;
  left: 0;
}
/* ラベルの線＿九州・沖縄 */
.area7 h3:before {
  width: 112%;
  left: 0;
}
.area7 h3:after{
  height: 16px;
  top: 30px;
  right: -12%;
}
/* PC版地図＿画像設定 */
.pc_map {
	background: url('../images/base.png') no-repeat;
	display: block;
	height: 494px;
	margin: 0 143px;
	position: relative;
	width: 594px;
}
.pc_map area {
  cursor: default;
}
.enable_area div{
	background-repeat: no-repeat;
	height: 494px;
	left: 0;
	margin: 0;
	position: absolute;
	top: 0;
	width: 594px;
}
.enable_area .area_1{ background-image: url('../images/area_1_enable.png'); }
.enable_area .area_2{ background-image: url('../images/area_2_enable.png'); }
.enable_area .area_3{ background-image: url('../images/area_3_enable.png'); }
.enable_area .area_4{ background-image: url('../images/area_4_enable.png'); }
.enable_area .area_5{ background-image: url('../images/area_5_enable.png'); }
.enable_area .area_6{ background-image: url('../images/area_6_enable.png'); }
.enable_area .area_7{ background-image: url('../images/area_7_enable.png'); }
.enable_area .area_1.hover{ background-image: url('../images/area_1.png'); }
.enable_area .area_2.hover{ background-image: url('../images/area_2.png'); }
.enable_area .area_3.hover{ background-image: url('../images/area_3.png'); }
.enable_area .area_4.hover{ background-image: url('../images/area_4.png'); }
.enable_area .area_5.hover{ background-image: url('../images/area_5.png'); }
.enable_area .area_6.hover{ background-image: url('../images/area_6.png'); }
.enable_area .area_7.hover{ background-image: url('../images/area_7.png'); }

/* フリーワード検索の設定 */
.wrap_form_area {
	background-color:#f1f1f1;
	margin-top: 95px;
}
.form_area {
    display: flex;
    padding: 20px 0 20px 23px;

    @include media(sp) {
        display: inline;
        padding: 0;
    }

    label {
        width: auto;
        font-size: 16px;
        line-height: 2.1;
        color: #666;

        @include media(sp) {
            font-size: 13px;
            line-height: 1.6;
        }
    }
}
.form_area input[type="text"] {
	border: 1px solid #cdcdcd;
	height: 34px;
	padding: 0 6px;
	width: 580px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
    margin: 0 3px 0 16px;
}
.form_area button[type="submit"] {
    background: url(#{$img-path}icon-search.svg) 6.5px 50% no-repeat $site-yellow;
    background-size: 17px;
	border: none;
	cursor: pointer;
	height: 34px;
	width: 108px;
	color: #fff;
	-webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: 1.6px;
    color: #333;
}
.form_area button[type="submit"] img {
	width: 16px;
	vertical-align: middle;
	margin-right: 6px;
}

.info-job-link {
    display: block;
    @include wh(600px);
    padding: 28px 0;
    margin: 37px auto 0;
    text-align: center;
    font-size: 26px;
    line-height: 1.75;
    color: #333;
    background: url(#{$img-path}icon-arrow.svg) 96% 50% no-repeat $site-yellow;
    background-size: 18px;

    @include media(sp) {
        width: 250px;
        padding: 16px 0;
        margin: 24px auto 0;
        font-size: 16px;
        background-size: 9px;
    }
}

/* スマホ版地図の設定 */
.sp_map,
.sp_area_title,
.sp_area_title ul {
	display: none;
}
.sp_map p{
	text-align: left;
}

@media screen and (max-width: 767px) {
  /* SP版レイアウト調整 */
	.box_map {
		width: 100%;
	}
	.box_map_inner {
		padding: 30px;
	}
	/* PC版地図を非表示 */
	.pc_map,
	.labelBox {
		display: none;
	}
	/* リストの設定 */
	.sp_map {
		border-bottom: 1px solid #333;
		display: block;
	}
	p.sp_map_title {
		padding-bottom: 10px;
		text-align: center;
	}
	.sp_area_title p {
		border-top: 1px solid #333;
		margin-bottom: 0;
		padding: 10px 0 10px 15px;
	}
	.sp_pref_title a {
    border-top: 1px dashed #666;
		color: #666;
		padding: 10px 35px;
		display: block;
    text-decoration: none;
	}
  .sp_pref_title:nth-of-type(1) a {
    border-top: 1px solid #666;
  }

	/* フリーワード検索の設定 */
	.wrap_form_area {
		padding: 20px;
    margin-top: 30px;
	}
	.form_area input[type="text"] {
		height: 46px;
		width: 100%;
        margin: 10px 0;
        
        @include media(sp) {
            height: auto;
            margin: 6px 0 14px;
            padding: 4px 5px;
            font-size: 15px;
        }
	}
	.form_area button[type="submit"] {
		height: 46px;
        width: 100%;
        
        @include media(sp) {
            display: block;
            height: 40px;
            width: 140px;
            margin: 0 auto;
            padding: 4px 5px;
            font-size: 15px;
        }
	}
}

.br-pc {
    display: block;

    @include media(sp) {
        display: inline;
    }
}