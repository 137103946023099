@charset "UTF-8";

/* ------------------------------
    footer
------------------------------ */
.footer {
    padding: 32px 0 24px;

    @include media(sp) {
        padding: 28px 0 24px;
        text-align: center;
    }

    .l-inner {
        display: flex;

        @include media(sp) {
            display: inline;
        }
    }

    // フッターナビゲーション
    &__nav {

        @include media(sp) {
            margin-bottom: 20px;
        }

        li {
            display: inline-block;
            padding-right: 13px;
            padding-left: 13px;
            @include border(left,solid,1px,$txt-color);

            &:first-child {
                padding-left: 0;
                border-left: 0;
            }
        } // li end

        // リンクスタイル
        a {
            font-size: 12px;
            font-weight: 300;
            line-height: 1;

        } // a end

    } // &__nav end

    // コピーライト
    &__copyright {
        margin-left: auto;
        font-size: 12px;
        font-weight: 300;
        line-height: 1;

        @include media(sp) {
            font-size: 11px;
        }
    } // &__copyright end

} // footer end